<template>
  <!-- <div>
    <h1>Lista de Pedidos</h1>
    <pre>{{ agente }}</pre>
    <pre>{{ orders }}</pre>
  </div> -->
  <b-card-actions
    title="Orders"
    class="mb-0"
    action-collapse
  >
    <!-- <div class="mb-1">
      <label>Show</label>
      <v-select
        v-model="perPage"
        :options="perPageOptions"
        :clearable="false"
        class="per-page-selector d-inline-block mx-50"
      />
      <label>entries of {{ orders.length }}</label>
    </div> -->
    <b-table
      id="listPedidosTable"
      ref="tableListPedidos"
      class="position-relative"
      :items="orders"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      table-variant="none"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: select -->
      <!-- <template #cell(select)="data">
        <b-form-checkbox
          :checked="isCheckedOrder(data.item.id)"
          @click.native.stop
          @change="$emit('updatePedidosSelected', data.item.id)"
        />
      </template> -->

      <!-- Column: Pedido -->
      <template #cell(pedido)="data">
        <b-media vertical-align="center">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            disabled
            @click="$emit('editPedido', data.item)"
          >
            R{{ splitedPedido(data.item.id) }}
          </b-link>
          <small class="text-muted">{{ data.item.fechaIso }}</small>
        </b-media>
      </template>

      <!-- Column: Cliente -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <!-- :to="{ name: 'bimbo-clientes-edit', params: { id: data.item.cliente_id } }" -->
          <b-link
            class="font-weight-bold d-block text-nowrap"
            disabled
          >
            {{ data.item.cliente_id }}
          </b-link>
          <small class="text-muted">{{ data.item.cliente_nombre }}</small>
        </b-media>
      </template>

      <!-- Column: zona -->
      <template #cell(cliente_ruta)="data">
        <div class="text-nowrap">
          <span class="text-right font-weight-bolder">{{ data.item.cliente_ruta }}</span>
        </div>
      </template>

      <!-- Column: ruta -->
      <!-- <template #cell(ruta)="data">
        <div
          class="text-nowrap"
          @click="$emit('pickingVehiculo', data.item)"
        >
          <vehiculo-icon
            :color="rutaColorIcon(data.item.ruta)"
            size="36"
          />
        </div>
      </template> -->
      <template #cell(status)="data">
        <b-media vertical-align="center">
          <b-link
            :class="`light-${resolveUserStatusVariant(data.item.status).color} font-weight-bold d-block text-nowrap`"
            disabled
          >
            {{ resolveUserStatusVariant(data.item.status).txt }}
          </b-link>
          <small class="text-muted">{{ data.item.fechaEntrega }}</small>
        </b-media>
        <!-- <b-media vertical-align="center">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status).color}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusVariant(data.item.status).txt }}
          </b-badge>
          <small class="text-muted">{{ data.item.fechaEntrega }}</small>
        </b-media> -->
      </template>

      <!-- Column: Actions -->
      <!-- <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="data.item.status ? 'flat-success' : 'flat-secondary'"
            class="btn-icon"
            size="sm"
            :disabled="data.item.is_picking_completed"
            @click="$emit('picking', data.item)"
          >
            <feather-icon
              :id="`order-row-${data.item.id}-cart-icon`"
              icon="ShoppingCartIcon"
              class="cursor-pointer"
              :color="data.item.status > 1 ? '#ffebeb' : ''"
              size="16"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="data.item.status ? 'flat-success' : 'flat-secondary'"
            class="btn-icon"
            size="sm"
            @click="$router.push({ name: 'bimbo-pedidos-ticket', params: { id: data.item.id } })"
          >
            <feather-icon
              :id="`order-row-${data.item.id}-print-icon`"
              icon="PrinterIcon"
              class="cursor-pointer"
              size="16"
            />
          </b-button>
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'bimbo-pedidos-ticket', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Print</span>
            </b-dropdown-item>

            <b-dropdown-item @click="$emit('editPedido', data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template> -->

    </b-table>

    <!-- Paginación -->
    <div
      v-if="false"
      class="mx-2 mb-2"
    >
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="orders.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card-actions>
</template>

<script>
import {
  BRow, BCol, BTable,
  BPagination, BMedia, BLink,
  // BBadge, BButton,
  // BDropdown, BDropdownItem,  BFormCheckbox,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import { ref, onMounted } from '@vue/composition-api'
import { useShopRemoteData } from './useShop'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BMedia,
    BCardActions,
    // BTooltip,
    // BAvatar,
    BLink,
    BPagination,
    // vSelect,
    // BButton,
    // BBadge,
    // BDropdown,
    // BDropdownItem,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const orders = ref([])
    const { fetchOrders } = useShopRemoteData()

    onMounted(async () => {
      orders.value = await fetchOrders()
    })

    return {
      orders,
      ...useShopRemoteData(),
    }
  },
}
</script>
